import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './offcanvas.scss';
import { useAppSelector } from '../../../../../store/hooks';
import { useAlert } from '../../../../../config/hooks/useAlert';
import { appCookie, Logout } from '../../../../../config/utils/Auth';
import { AppSectionInterface } from '../../../../../interface';
import { RemoveCookie } from '../../../../../config/utils/Cookie';
import { GetStorage } from '../../../../../config/utils/Storage';
import { lang } from './langs';
import { Tooltip } from 'antd';

const deffaultRole =
    parseInt(GetStorage(process.env.REACT_APP_PUBLIC_DEFFAULT_ROLE)) || 0;
const OffComponent = () => {
    const { userData, preferences } = useAppSelector(state => state.users);
    const { roles } = userData;
    const { appSections } = roles[deffaultRole];

    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            logout: { title, message, okButton, cancelButton },
            menuPage: { /* notificationsTitle, helpTitle, */ homeTitle }
        }
    } = lang;
    const navigate = useNavigate();

    useEffect(() => {
        setSections(appSections);
    }, [appSections]);

    const { AlertElement, alertManagerRef, ConfigAlert, setConfigAlert } =
        useAlert();
    const [sections, setSections] = useState<AppSectionInterface[]>([]);

    const OnLogout = async () => {
        RemoveCookie(appCookie);
        RemoveCookie(process.env.REACT_APP_PUBLIC_USER_DATA_COOKIE);
        setTimeout(() => {
            Logout(navigate);
        }, 100);
    };

    const ClickLogout = () => {
        ConfigAlert.title = title;
        ConfigAlert.message = message;
        ConfigAlert.okButtonText = okButton;
        ConfigAlert.okButtonController = { func: OnLogout };
        ConfigAlert.cancelButtonText = cancelButton;
        setConfigAlert(ConfigAlert);
    };
    const numColumns = sections.filter(item => item.isIcon).length + 3;

    return (
        <div className='main_menu'>
            <AlertElement ref={alertManagerRef} />

            <div
                className='main_menu__grid-container'
                style={{ gridTemplateColumns: `repeat(${numColumns}, 1fr)` }}
            >
                {sections.map(item => {
                    return (
                        item.isIcon && (
                            <Tooltip
                                title={
                                    <span className='main_menu__grid-container__tooltip'>
                                        {item.name}
                                    </span>
                                }
                                key={item.id}
                                color='#fff'
                            >
                                <div className='main_menu__grid-container__item'>
                                    <Link
                                        to={`/panel/${item.path}`}
                                        className='main_menu__grid-container__item'
                                    >
                                        {item.icon !== null ? (
                                            <img
                                                className='main_menu__grid-container__item__img'
                                                src={`/svg-icons/${item.icon}.svg`}
                                            />
                                        ) : (
                                            item.name
                                        )}
                                    </Link>
                                </div>
                            </Tooltip>
                        )
                    );
                })}
                <Tooltip
                    title={
                        <span className='main_menu__grid-container__tooltip'>
                            PRÓXIMAMENTE
                        </span>
                    }
                    color='#fff'
                >
                    <div className='main_menu__grid-container__item'>
                        <Link
                            to='/panel'
                            key='2'
                            className='main_menu__grid-container__item'
                        >
                            <img
                                className='main_menu__grid-container__item__img disabled'
                                src={`/svg-icons/AlertIconW.svg`}
                            />
                        </Link>
                    </div>
                </Tooltip>

                <Tooltip
                    title={
                        <span className='main_menu__grid-container__tooltip'>
                            {homeTitle}
                        </span>
                    }
                    color='#fff'
                >
                    <div className='main_menu__grid-container__item'>
                        <Link
                            to='/panel'
                            key='3'
                            className='main_menu__grid-container__item'
                        >
                            <img
                                className='main_menu__grid-container__item__img'
                                src={`/svg-icons/HomeIcon.svg`}
                            />
                        </Link>
                    </div>
                </Tooltip>

                <Tooltip
                    title={
                        <span className='main_menu__grid-container__tooltip'>
                            {title}
                        </span>
                    }
                    color='#fff'
                >
                    <div className='main_menu__grid-container__item'>
                        <button onClick={ClickLogout}>
                            <img
                                className='main_menu__grid-container__item__img'
                                src={`/svg-icons/LogoutIcon.svg`}
                            />
                        </button>
                    </div>
                </Tooltip>
            </div>
        </div>
    );
};

export default OffComponent;
