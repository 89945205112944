import { useRef, useState } from 'react';
import { useCallApi } from '../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../config/hooks/useCallApi/constants';
import CloseIcon from '@material-ui/icons/Close';
import { ConfigProvider, DatePicker } from 'antd';
import esES from 'antd/es/locale/es_ES';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { PropertyInterface, RegisterInterface } from '../../interface';
import './download-requirement-files.scss';
import { useToast } from '../../config/hooks/useToast';

dayjs.locale('es');

export interface Material {
    id?: string;
    name: string;
    selected: boolean;
}

export default function DownloadRequirementsFiles({
    registers,
    hasDateFilter = false,
    propertyUnits,
    style = 'button'
}: {
    registers?: RegisterInterface[];
    hasDateFilter?: boolean;
    propertyUnits?: PropertyInterface[];
    style?: string;
}) {
    const { GetData, LoaderElement } = useCallApi();

    const [disableButton, setDisableButton] = useState<boolean>(false);
    const [showPopUp, setShowPopUp] = useState<boolean>(false);
    const [dateFilter, setDateFilter] = useState<{
        dateIni: string;
        dateFin: string;
    }>({
        dateIni: (() => {
            const currentDate = new Date();
            currentDate.setDate(1);
            return currentDate.toISOString().split('T')[0];
        })(),
        dateFin: (() => {
            // last day of current month
            const currentDate = new Date();
            const lastDay = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 1,
                0
            );
            return lastDay.toISOString().split('T')[0];
        })()
    });
    const downloadLink = useRef('');
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();

    const title = 'Descarga de Documentos';

    const changeDate = date => {
        if (date === null) {
            return;
        }

        const startDate = new Date(date);
        startDate.setDate(1);
        const formattedStartDate = startDate.toISOString().split('T')[0];

        const endDate = new Date(date);
        const lastDay = new Date(
            endDate.getFullYear(),
            endDate.getMonth() + 1,
            0
        );
        const formattedEndDate = lastDay.toISOString().split('T')[0];

        setDateFilter({
            dateIni: formattedStartDate,
            dateFin: formattedEndDate
        });
    };

    const handleButton = async () => {
        setShowPopUp(true);
    };

    const downloadFiles = async () => {
        setDisableButton(true);
        try {
            await obtainDownloadLink();
            if (downloadLink.current) {
                const link = document.createElement('a');
                link.href = downloadLink.current;
                link.download = 'download.zip';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            console.log(error);
        }

        setDisableButton(false);
    };

    const obtainDownloadLink = async () => {
        if (propertyUnits && propertyUnits.length === 0) {
            ConfigToast.text = 'Debes seleccionar al menos un inmueble';
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
            return;
        }

        const url = `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/file/register-zip-url`;
        const body = {
            registerIds: registers,
            startDate: dateFilter.dateIni,
            endDate: dateFilter.dateFin,
            propertyUnits: propertyUnits?.map(item => item.id)
        };

        const response = await GetData(url, HTTP_METHODS.POST, body);

        if (response.status) {
            downloadLink.current = response.data.zipUrl;
        }
    };

    return (
        <div className='download-register-files--content'>
            <ToastElement ref={toastManagerRef} />
            {disableButton && <LoaderElement />}
            {style === 'button' ? (
                <button
                    className='button button-body--register-download'
                    onClick={handleButton}
                    disabled={disableButton}
                >
                    Descargar archivos de registros
                </button>
            ) : (
                <div className='download-register-files--content--text-button'>
                    <div>
                        <a
                            href='#'
                            onClick={e => {
                                e.preventDefault();
                                handleButton();
                            }}
                        >
                            <img
                                className='header__breadcrumb__title__imgLogo'
                                src={`/svg-icons/DownloadIcon.svg`}
                            />
                            Descargar archivos de Registros
                        </a>
                    </div>
                </div>
            )}

            {showPopUp && (
                <div className='popUp'>
                    <div className='popUp__main'>
                        <div className='popUp__main__container'>
                            <div className='popUp__main__title title'>
                                <span> {title} </span>
                                <CloseIcon
                                    className='popUp__main__title__close'
                                    onClick={() => {
                                        setShowPopUp(false);
                                    }}
                                />
                                <hr />
                            </div>
                            <div className='popUp__main__text subtitle'>
                                {hasDateFilter && (
                                    <div className='popUp__main__text__date-picker'>
                                        <div className='popUp__main__text__date-picker__title'>
                                            Selecione una fecha:
                                        </div>
                                        <ConfigProvider
                                            locale={esES}
                                            theme={{
                                                components: {
                                                    DatePicker: {
                                                        colorLink: '#a2a2a3',
                                                        colorLinkActive:
                                                            '#a2a2a3',
                                                        colorPrimary: '#a2a2a3',
                                                        colorPrimaryBorder:
                                                            '#a2a2a3',
                                                        colorPrimaryHover:
                                                            '#a2a2a3',
                                                        colorBgContainer:
                                                            '#f3f2f5',
                                                        colorBorder: '#fff',
                                                        fontFamily: 'Roboto',
                                                        colorIcon: '#a2a2a3',
                                                        colorText: '#C4C3C5',
                                                        colorTextHeading:
                                                            '#a2a2a3',
                                                        colorTextPlaceholder:
                                                            '#a2a2a3'
                                                    }
                                                }
                                            }}
                                        >
                                            <DatePicker
                                                onChange={changeDate}
                                                picker='month'
                                                disabledDate={current =>
                                                    current?.isAfter(
                                                        dayjs(),
                                                        'day'
                                                    )
                                                }
                                                defaultValue={dayjs(
                                                    dateFilter.dateIni,
                                                    'YYYY-MM-DD'
                                                )}
                                            />
                                        </ConfigProvider>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='popUp__main__buttons app-d-flex__center childs_uniform'>
                            <button
                                className={`button button-body--register-download ${
                                    disableButton && 'disabled'
                                }`}
                                onClick={downloadFiles}
                            >
                                {disableButton ? 'Cargando...' : 'Descargar'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
