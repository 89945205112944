import './components/components.scss';
import './certificate.scss';
import { PDFExport } from '@progress/kendo-react-pdf';
import { useEffect, useState } from 'react';
import ChartFormated from '../../monthlyEvolutionCard/components/chartFormated';
import EcoequivalencesCard from '../../ecoequivalencesCard/ecoequivalencesCard';
import BZeroIndexCard from '../../bzeroIndexCard/bzeroindexcard';
import { lang } from '../../../langs';
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';
import TableSummary from './components/summary-table';
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
import { ApiRequirementCertificates } from '../../../../config/service';
import { GetBzeroIndex } from '../../../../config/utils/IdbFormatData';

interface TableItem {
    finalDisposal: string;
    gestorId: string;
    gestorName: string;
    materialId: string;
    materialName: string;
    total: string;
}

interface Props {
    data: any;
    ecoequivalences: any;
    name: string[];
    propertyId?: string[];
    propertyUnitId?: string[];
    month: string | null;
    monthNumber?: string | null;
    colorsDB: any;
    year: number;
    show: boolean;
    /* onReady: any; */
    pdfExportRef: any;
}
const BalanceCertificate = ({
    data,
    ecoequivalences,
    name,
    propertyId,
    propertyUnitId,
    month,
    monthNumber,
    year,
    show,
    /* onReady, */
    pdfExportRef
}: Props) => {
    const { userData, preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            dataCenterComponents: {
                monthlyClosureCard: {
                    months,
                    certificatesTxts: {
                        footerTxt,
                        finalDisposal: { tableTitle },
                        balance: {
                            title,
                            yearTitle,
                            text,
                            chartTitle,
                            ecoeqTitle
                        }
                    }
                }
            }
        }
    } = lang;
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const [tableData, setTableData] = useState<TableItem[]>([]);
    const [formattedLabels, setFormattedLabels] = useState<string[]>([]);
    const [periodData, setPeriodData] = useState<any>();
    const [indexMonth, setIndexMonth] = useState<number>(0);
    const [indexTrim, setIndexTrim] = useState<number>(0);
    const [range, setRange] = useState<number[]>([]);

    const GetMaterials = async () => {
        const values = {
            propertyId: propertyId ?? null,
            propertyUnitId: propertyUnitId ?? null,
            dateYear: year,
            dateMont: monthNumber /* '01' */
        };
        const response = await GetData(
            ApiRequirementCertificates,
            HTTP_METHODS.POST,
            values
        );
        response.data.sort((a, b) =>
            a.materialColor.localeCompare(b.materialColor)
        );
        setTableData(response.data);
    };

    useEffect(() => {
        if (show) {
            GetMaterials();
        }
    }, [monthNumber, year, propertyId, propertyUnitId]);

    const fechaActual = new Date();
    const fechaActualFormateada = fechaActual.toLocaleString();

    useEffect(() => {
        if (monthNumber) {
            const month =
                monthNumber && parseInt(monthNumber) < 10
                    ? `0${monthNumber}`
                    : `${monthNumber}`;
            const targetLabel = `${year}${month}`;
            const targetIndex = parseInt(data.labels.indexOf(targetLabel));
            let last12Labels;
            let datasetsLast12;
            if (targetIndex === -1 || targetIndex < 11) {
                last12Labels = data.labels.slice(0, targetIndex);
                datasetsLast12 = data.datasets.map(dataset => ({
                    ...dataset,
                    data: dataset.data.slice(0, targetIndex)
                }));
                setRange([0, targetIndex]);
            } else {
                last12Labels = data.labels.slice(
                    targetIndex - 11,
                    targetIndex + 1
                );
                datasetsLast12 = data.datasets.map(dataset => ({
                    ...dataset,
                    data: dataset.data.slice(targetIndex - 11, targetIndex + 1)
                }));
                setRange([targetIndex - 11, targetIndex + 1]);
            }
            formatLabelsShow(last12Labels);
            const selectedData = {
                labels: last12Labels,
                datasets: datasetsLast12
            };
            setPeriodData(selectedData);
        } else {
            const yearLabels = data.labels.filter(label =>
                label.startsWith(`${year}`)
            );
            const yearIndexes: number[] = yearLabels.map(label =>
                data.labels.indexOf(label)
            );
            const datasetsYear = data.datasets.map(dataset => ({
                ...dataset,
                data: yearIndexes.map(index => dataset.data[index])
            }));

            setRange([
                yearIndexes[0] + 1,
                yearIndexes[yearIndexes.length - 1] + 1
            ]);
            formatLabelsShow(yearLabels);
            const selectedYearData = {
                labels: yearLabels,
                datasets: datasetsYear
            };
            setPeriodData(selectedYearData);
        }
    }, [monthNumber, year, data]);

    const formatLabelsShow = labelsArray => {
        const formatted = labelsArray.map(label => {
            const month = label.substring(4, 6);
            return `${months[parseInt(month) - 1].slice(0, 1)}`;
        });
        setFormattedLabels(formatted);
    };

    useEffect(() => {
        if (range.length > 1) {
            if (monthNumber) {
                const bzeroindexmonth = GetBzeroIndex(data, 1, range[1]);
                setIndexMonth(bzeroindexmonth);
            } else {
                const bzeroindexmonth = GetBzeroIndex(data, 12, range[1]);
                setIndexMonth(bzeroindexmonth);
            }
            const bzeroindextrim = GetBzeroIndex(data, 3, range[1]);
            setIndexTrim(bzeroindextrim);
        }
    }, [range, data]);
    return (
        <div className='pdf-container'>
            <PDFExport
                margin={{ top: '0.8cm', left: 0, right: 0, bottom: 0 }}
                forcePageBreak={undefined}
                ref={pdfExportRef}
                fileName='cert_balance'
            >
                <div className='certificate'>
                    <div className='certificate__header-balance'>
                        <div className='certificate__header-balance__info'>
                            <div className='certificate__header-balance__info__text'>
                                <h2>
                                    {' '}
                                    {userData?.clientData &&
                                    userData.clientData.length > 0 &&
                                    userData?.clientData[0].client?.name
                                        ? `${userData.clientData[0].client.name} | ${userData.clientData[0].client.rut} | ${userData.clientData[0].client.address}`
                                        : ''}
                                </h2>
                                <h2>
                                    {' '}
                                    Descargado el: {fechaActualFormateada}{' '}
                                </h2>
                            </div>
                        </div>
                        <div className='certificate__header-balance__title'>
                            <div className='certificate__header-balance__title__logo'>
                                <img
                                    className='certificate__header-balance__title__logo__img'
                                    src={`${
                                        userData?.clientData &&
                                        userData.clientData.length > 0 &&
                                        userData?.clientData[0].client?.image
                                            ? userData.clientData[0].client
                                                  .image
                                            : '/certificates/pryma.png'
                                    }`}
                                />
                            </div>
                            <div className='certificate__header-balance__title__text'>
                                <div className='certificate__header-balance__title__text__cert-name'>
                                    {monthNumber === null ? yearTitle : title} -
                                    {month !== 'null' ? ` ${month}` : ''} {year}
                                </div>
                                <div className='certificate__header-balance__title__text__property-name'>
                                    {name.join(' || ')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='certificate__section'>
                        <p> {text}</p>
                    </div>
                    <div className='certificate__section'>
                        <h1 className='certificate__section__title'>
                            {' '}
                            {tableTitle}{' '}
                        </h1>
                        <div className='certificate__section__table'>
                            <TableSummary data={tableData} />
                        </div>
                    </div>
                    <div className='page-break'></div>
                    <div className='certificate__section'>
                        <h1 className='certificate__section__title'>
                            {' '}
                            {chartTitle}{' '}
                        </h1>
                        <div className='certificate__section__chart'>
                            {periodData && (
                                <ChartFormated
                                    data={periodData}
                                    formattedLabels={formattedLabels}
                                    devicePixelRatio={35}
                                />
                            )}
                        </div>
                    </div>
                    <div className='certificate__section'>
                        <h1 className='certificate__section__title'>
                            {' '}
                            Índice BZero{' '}
                        </h1>
                        <div className='certificate__section__index'>
                            <p className='certificate__section__index__text'>
                                ¿Qué es el Índice BZero?
                                <br />
                                El índice BZero es el porcentaje de residuos que
                                son destinados a rellenos sanitarios respecto
                                del total de residuos generados.
                            </p>
                            <div className='certificate__section__index__chart'>
                                <div className='certificate__section__index__chart__title'>
                                    {month !== 'null' ? `${month}` : ''} {year}
                                </div>
                                <BZeroIndexCard
                                    index={indexMonth}
                                    certificate={true}
                                    devicePixelRatio={35}
                                />
                            </div>
                            <div className='certificate__section__index__chart'>
                                <div className='certificate__section__index__chart__title'>
                                    {month !== 'null'
                                        ? `Trimestral`
                                        : 'Último trimestre'}
                                </div>
                                <BZeroIndexCard
                                    index={indexTrim}
                                    certificate={true}
                                    devicePixelRatio={35}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='certificate__section'>
                        <h1 className='certificate__section__title'>
                            {' '}
                            {ecoeqTitle}{' '}
                        </h1>
                        <div className='certificate__section__ecoeq'>
                            <EcoequivalencesCard
                                data={ecoequivalences}
                                certificate={true}
                                rangeCertificate={range}
                                anual={monthNumber === null}
                            />
                        </div>
                    </div>
                    <div className='certificate__section'>
                        <p className='certificate__section__text'>
                            {footerTxt}
                        </p>
                    </div>
                    <div className='certificate__footer'>
                        <img
                            className='certificate__footer-img'
                            src='/certificates/footer.jpg'
                        />
                    </div>
                </div>
            </PDFExport>
            {LoadingData && <LoaderElement />}
        </div>
    );
};

export default BalanceCertificate;
